.edit-stock-variant span,
.edit-stock-variant p {
    width: 14rem;
    text-align: right;
    color: #5C5C5C;
}

.edit-stock-variant .nomor-telepon p {
    width: 16rem;
}

.edit-stock-variant .edit-stock-variant-p p,
.edit-stock-variant.edit-stock-variant-p span {
    color: #5C5C5C;
    width: 21rem;
    
}
