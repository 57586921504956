/*start toggle-primary small style ceklist tricky */
.wrap-toggle .dropdown.active {
  /* right: 0; */
}

.wrap-toggle-primary {
  position: relative;
  cursor: pointer;
}
.wrap-toggle-primary:hover .toggle-primary {
  border-color: #0025B8;
}
.wrap-toggle-primary:hover .toggle-default-primary {
  background-color: #0025B8;
}
.wrap-toggle-primary-large:hover .toggle-primary-large {
  border-color: #0025B8;
}
.wrap-toggle-primary-large:hover .toggle-default-primary-large {
  background-color: #0025B8;
}

.wrap-toggle-primary input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}
.toggle-primary {
  position: absolute;
  top: 0;
  left: 0;
  height: 16px;
  width: 30px;
  background-color: #fff;
  border: 1px solid #8f8f8f;
  border-radius: 100px;
}

.toggle-default-primary {
  position: absolute;
  left: 2.7px;
  top: 1.9px;
  width: 12px;
  height: 12px;
  border-radius: 100px;
  background-color: #8f8f8f;
}
.wrap-toggle-primary input:checked ~ .toggle-primary {
  background-color: #1f4bff;
  border: 1px solid #1f4bff;
}
.wrap-toggle-primary input:checked ~ .toggle-primary:hover,
.wrap-toggle-primary-large input:checked~.toggle-primary-large:hover {
  background-color: #0025B8;
}
.wrap-toggle-primary input:checked ~ .toggle-default-primary {
  display: none;
}

.toggle-primary:after {
  content: "";
  position: absolute;
  display: none;
}
.wrap-toggle-primary input:checked ~ .toggle-primary:after {
  display: block;
}
.wrap-toggle-primary .toggle-primary:after {
  top: 48%;
  transform: translateY(-50%);
  right: 2px;
  width: 12px;
  height: 12px;
  border-radius: 100px;
  background-color: #fff;
}
/*end toggle-primary small style toggle tricky */

/*start toggle-secondary small style ceklist tricky */
.wrap-toggle-secondary {
  position: relative;
  cursor: pointer;
}
.wrap-toggle-secondary input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}
.toggle-secondary {
  position: absolute;
  top: 0;
  left: 0;
  height: 16px;
  width: 30px;
  background-color: #fff;
  border: 1px solid #0025b8;
  border-radius: 100px;
}
.toggle-default-secondary {
  position: absolute;
  left: 2.7px;
  top: 1.9px;
  width: 12px;
  height: 12px;
  border-radius: 100px;
  background-color: #0025b8;
}
.wrap-toggle-secondary input:checked ~ .toggle-secondary {
  background-color: #0025b8;
  border: 1px solid #0025b8;
}
.wrap-toggle-secondary input:checked ~ .toggle-default-secondary {
  display: none;
}
.toggle-secondary:after {
  content: "";
  position: absolute;
  display: none;
}
.wrap-toggle-secondary input:checked ~ .toggle-secondary:after {
  display: block;
}
.wrap-toggle-secondary .toggle-secondary:after {
  top: 48%;
  transform: translateY(-50%);
  right: 2px;
  width: 12px;
  height: 12px;
  border-radius: 100px;
  background-color: #fff;
}
/*end toggle-secondary small style toggle tricky */
/*start toggle-disabled small style ceklist tricky */
.wrap-toggle-disabled {
  position: relative;
  cursor: pointer;
}
.wrap-toggle-disabled input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}
.toggle-disabled {
  position: absolute;
  top: 0;
  left: 0;
  height: 16px;
  width: 30px;
  background-color: #f5f5f5;
  border: 1px solid #c2c2c2;
  border-radius: 100px;
}
.toggle-default-disabled {
  position: absolute;
  left: 2.7px;
  top: 1.9px;
  width: 12px;
  height: 12px;
  border-radius: 100px;
  background-color: #c2c2c2;
}
.wrap-toggle-disabled input:checked ~ .toggle-disabled {
  background-color: #f5f5f5;
}
.wrap-toggle-disabled input:checked ~ .toggle-default-disabled {
  display: none;
}
.toggle-disabled:after {
  content: "";
  position: absolute;
  display: none;
}
.wrap-toggle-disabled input:checked ~ .toggle-disabled:after {
  display: block;
}
.wrap-toggle-disabled .toggle-disabled:after {
  top: 48%;
  transform: translateY(-50%);
  right: 2px;
  width: 12px;
  height: 12px;
  border-radius: 100px;
  background-color: #c2c2c2;
}

/*end toggle-disabled small style toggle tricky */

/*start toggle-primary large style ceklist tricky */
.wrap-toggle-primary-large {
  position: relative;
  cursor: pointer;
}
.wrap-toggle-primary-large input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}
.toggle-primary-large {
  position: absolute;
  top: 0;
  left: 0;
  height: 24px;
  width: 45px;
  background-color: #fff;
  border: 1px solid #8f8f8f;
  border-radius: 100px;
}
.toggle-default-primary-large {
  position: absolute;
  left: 2.7px;
  top: 2.9px;
  width: 18px;
  height: 18px;
  border-radius: 100px;
  background-color: #8f8f8f;
}
.wrap-toggle-primary-large input:checked ~ .toggle-primary-large {
  background-color: #1f4bff;
  border: 1px solid #1f4bff;
}
.wrap-toggle-primary-large input:checked ~ .toggle-default-primary-large {
  display: none;
}
.toggle-primary-large:after {
  content: "";
  position: absolute;
  display: none;
}
.wrap-toggle-primary-large input:checked ~ .toggle-primary-large:after {
  display: block;
}
.wrap-toggle-primary-large .toggle-primary-large:after {
  top: 50%;
  transform: translateY(-50%);
  right: 2px;
  width: 18px;
  height: 18px;
  border-radius: 100px;
  background-color: #fff;
}
/*end toggle-primary large style toggle tricky */

/*start toggle-secondary large style ceklist tricky */
.wrap-toggle-secondary-large {
  position: relative;
  cursor: pointer;
}
.wrap-toggle-secondary-large input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}
.toggle-secondary-large {
  position: absolute;
  top: 0;
  left: 0;
  height: 24px;
  width: 45px;
  background-color: #fff;
  border: 1px solid #0025b8;
  border-radius: 100px;
}
.toggle-default-secondary-large {
  position: absolute;
  left: 2.7px;
  top: 2.9px;
  width: 18px;
  height: 18px;
  border-radius: 100px;
  background-color: #0025b8;
}
.wrap-toggle-secondary-large input:checked ~ .toggle-secondary-large {
  background-color: #0025b8;
  border: 1px solid #0025b8;
}
.wrap-toggle-secondary-large input:checked ~ .toggle-default-secondary-large {
  display: none;
}
.toggle-secondary-large:after {
  content: "";
  position: absolute;
  display: none;
}
.wrap-toggle-secondary-large input:checked ~ .toggle-secondary-large:after {
  display: block;
}
.wrap-toggle-secondary-large .toggle-secondary-large:after {
  top: 50%;
  transform: translateY(-50%);
  right: 2px;
  width: 18px;
  height: 18px;
  border-radius: 100px;
  background-color: #fff;
}
/*end toggle-secondary large style toggle tricky */

/*start toggle-secondary-disabled large style ceklist tricky */
.wrap-toggle-disabled-large {
  position: relative;
  cursor: pointer;
}

.wrap-toggle-disabled-large input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.toggle-disabled-large {
  position: absolute;
  top: 0;
  left: 0;
  height: 24px;
  width: 45px;
  background-color: #f5f5f5;
  border: 1px solid #c2c2c2;
  border-radius: 100px;
}

.toggle-default-disabled-large {
  position: absolute;
  left: 2.7px;
  top: 2.9px;
  width: 18px;
  height: 18px;
  border-radius: 100px;
  background-color: #c2c2c2;
}

.wrap-toggle-disabled-large input:checked ~ .toggle-disabled-large {
  background-color: #f5f5f5;
}

.wrap-toggle-disabled-large input:checked ~ .toggle-default-disabled-large {
  display: none;
}

.toggle-disabled-large:after {
  content: "";
  position: absolute;
  display: none;
}

.wrap-toggle-disabled-large input:checked ~ .toggle-disabled-large:after {
  display: block;
}

.wrap-toggle-disabled-large .toggle-disabled-large:after {
  top: 50%;
  transform: translateY(-50%);
  right: 2px;
  width: 18px;
  height: 18px;
  border-radius: 100px;
  background-color: #c2c2c2;
}

/*end toggle-secondary-disabled large style toggle tricky */
