@layer components {
  .topbar {
    @apply bg-white w-full h-16 shadow-gray flex justify-between relative px-5;
  }
  .title {
    @apply text-gray-500 font-semibold text-lg self-center ml-3;
  }
  .brand {
    @apply text-blue-hedgehog font-semibold text-lg self-center;
  }
}
