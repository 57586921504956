.setting-struktur-digital.logo-sello svg {
  width: 3rem;
  height: 3rem;
  margin-right: 0;
}

.primary {
  @apply bg-blue1;
}

.blue {
  @apply bg-blue2;
}

.soft_blue {
  @apply bg-blue3;
}

.tosca {
  @apply bg-blue4;
}

.green {
  @apply bg-green1;
}

.soft_green {
  @apply bg-green2;
}
.red {
  @apply bg-red1;
}
.pink {
  @apply bg-red2;
}

.soft_red {
  @apply bg-red3;
}
.purple {
  @apply bg-purple1;
}

.coffee {
  @apply bg-brown1;
}

.orange {
  @apply bg-brown2;
}

.gold {
  @apply bg-brown3;
}

.sun {
  @apply bg-brown4;
}

.black {
  @apply bg-black1;
}

.dark_grey {
  @apply bg-black2;
}

.grey {
  @apply bg-black3;
}

.light_grey {
  @apply bg-black4;
}
