.device p {
  display: none;
}
.device .text-field-small {
  padding: 0 0;
  border: none;
}
.device ul {
  top: 1.3rem;
}

.device-tipe-perangkat ul {
}

.create-device span {
    position: relative;
    top: 9px;
}
