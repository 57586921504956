/*start checkbox small style ceklist-light-dark tricky */
.wrap-checkbox-light-dark {
    position: relative;
    cursor: pointer;
}

.wrap-checkbox-light-dark input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}

.ceklist-light-dark {
    position: absolute;
    top: 0;
    left: 0;
    /*ukuran checkbox*/
    height: 40px;
    width: 40px;
    background-color: #FFFFFF;
    /*warna border default*/
    border: 1px solid #F5F5F5;
    border-radius: 8px;
}

.ceklist-light-dark:hover {
    /* border: 1px solid #5C5C5C; */
}

.wrap-checkbox-light-dark input:checked~.ceklist-light-dark {
    /*warna background ketika di cheklist*/
    background-color: #FFFFFF;
}

.wrap-checkbox-light-dark input:checked~.ceklist-light-dark {
    /*warna background ketika di cheklist*/
    background-color: #FFFFFF;
}

.wrap-checkbox-light-dark input:checked~.ceklist-light-dark:hover {
    /*warna background ketika di cheklist*/
    background-color: #FFFFFF;
}

.ceklist-light-dark:after {
    content: "";
    position: absolute;
    display: none;
}

.wrap-checkbox-light-dark input:checked~.ceklist-light-dark:after {
    display: block;
}

.wrap-checkbox-light-dark .ceklist-light-dark:after {
    top: 18%;
    left: 36%;
    transform: translate(-50%, -50%);
    width: 11px;
    height: 19px;
    border: solid #000D23;
    border-width: 0 4px 4px 0;
    transform: rotate(45deg);
    border-radius: 3px;
}

/*end checkbox small style ceklist-light-dark tricky */