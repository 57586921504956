@layer components {
  .text-field-small {
    @apply w-60 pl-4 py-2 border text-sm duration-500 focus:outline-none outline-none placeholder-gray-03 border-gray-04 rounded-lg;
  }
  /* .container-body {
    @apply bg-gray-05 p-5 h-full overflow-scroll pl-20 border-b  border-t border-gray-300;
  } */
  .container-body {
    @apply bg-gray-05 p-5 h-full overflow-scroll border-b  border-t border-gray-300;
  }
  .font-small-gray {
    @apply text-sm font-medium text-gray-02 text-right mr-5;
  }
  .input-material {
    @apply font-medium text-sm focus:outline-none focus:shadow-none;
  }
  .frame-parent {
    @apply flex w-full rounded-lg bg-gray-200 p-8 mt-5;
  }
  .frame-children {
    @apply flex justify-between bg-white rounded-lg border border-gray-400 p-5 text-sm text-gray-02 mt-3;
  }
  .grid-action {
    @apply grid grid-cols-2 gap-2 mt-10;
  }
}
