@layer components {
  .tab-container {
    @apply w-full h-screen bg-white pt-20;
  }
  .tab-menu {
    @apply flex p-3 font-medium text-gray-400  rounded-lg;
  }
  .tab-menu-children {
    @apply flex mx-5 p-3 pl-8 font-medium text-gray-400 rounded-lg;
  }
}
