/* CREATE MODAL */
.create-customer span,
.create-customer p {
  width: 16rem;
}
.create-customer .nomor-telepon p {
  width: 21.5rem;
}
.create-customer .nomor-telepon p.dash {
  width: inherit;
}
