/*start checkbox small style ceklist-dark-green tricky */
.wrap-checkbox-dark-green {
    position: relative;
    cursor: pointer;
}

.wrap-checkbox-dark-green input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}

.ceklist-dark-green {
    position: absolute;
    top: 0;
    left: 0;
    /*ukuran checkbox*/
    height: 40px;
    width: 40px;
    background-color: #06884A;
    /*warna border default*/
    /* border: 1px solid #8f8f8f; */
    border-radius: 8px;
}

.ceklist-dark-green:hover {
    /* border: 1px solid #06884A; */
}

.wrap-checkbox-dark-green input:checked~.ceklist-dark-green {
    /*warna background ketika di cheklist*/
    background-color: #06884A;
}

.wrap-checkbox-dark-green input:checked~.ceklist-dark-green {
    /*warna background ketika di cheklist*/
    background-color: #06884A;
}

.wrap-checkbox-dark-green input:checked~.ceklist-dark-green:hover {
    /*warna background ketika di cheklist*/
    background-color: #06884A;
}

.ceklist-dark-green:after {
    content: "";
    position: absolute;
    display: none;
}

.wrap-checkbox-dark-green input:checked~.ceklist-dark-green:after {
    display: block;
}

.wrap-checkbox-dark-green .ceklist-dark-green:after {
    top: 18%;
    left: 36%;
    transform: translate(-50%, -50%);
    width: 11px;
    height: 19px;
    border: solid white;
    border-width: 0 4px 4px 0;
    transform: rotate(45deg);
    border-radius: 3px;
}

/*end checkbox small style ceklist-dark-green tricky */