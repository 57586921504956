.inputBtn::-webkit-file-upload-button {
  visibility: hidden;
}
.inputBtn {
  color: transparent;
  width:200px;
  outline: none;
}

.inputBtn::before {
  content: "Tambah Foto";
  -webkit-user-select: none;
  display: inline-block;
  font-weight: 600;
  font-size: 14px;
  color: #1f4bff;
  background-color: #ebeeff;
  padding: 8px 16px;
  border-radius: 8px;
  transition: 0.2s;
}

.inputBtn:hover::before {
    color: #0025B8;
    cursor: pointer;
}

.inputEditBtn:active::before {
    color: #002952;
}
.inputEditBtn:focus::before {
    outline: none;
}


.inputEditBtn::-webkit-file-upload-button {
  visibility: hidden;
}
.inputEditBtn {
  color: transparent;
  width:200px;
  outline: none;
}

.inputEditBtn::before {
  content: "Ubah foto";
  -webkit-user-select: none;
  display: inline-block;
  font-weight: 600;
  font-size: 14px;
  color: #1f4bff;
  background-color: #ebeeff;
  padding: 8px 16px;
  border-radius: 8px;
  transition: 0.2s;
}

.inputEditBtn:hover::before {
    color: #0025B8;
    cursor: pointer;
}

.inputEditBtn:active::before {
    color: #002952;
}
.inputEditBtn:focus::before {
    outline: none;
}




