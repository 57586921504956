body{
  color: #000D23 !important;
}
.title{
  font-weight: 700 !important;
  font-size: 48px !important;
  line-height: 56px !important;
  letter-spacing: -0.96px !important;
  color: #000D23 !important;
}
.heading-0,
.heading-1,
.heading-2,
.heading-3,
.bold-text-1,
.bold-text-2,
.bold-text-3{
  font-weight: 600 !important;
}

.text-1,
.text-2,
.text-3{
  font-weight: 400 !important;
}

.heading-0{
  font-size: 40px !important;
  line-height: 48px !important;
  letter-spacing: -0.8px !important;
}
.heading-1{
font-size: 32px !important;
line-height: 40px !important;
letter-spacing: -0.64px !important;
}
.heading-2{
font-size: 32px !important;
line-height: 40px !important;
letter-spacing: -0.56px !important;
}
.heading-3{
font-size: 20px !important;
line-height: 28px !important;
letter-spacing: -0.4px !important;
}
.bold-text-1,
.text-1 {
  font-size: 16px !important;
  line-height: 24px !important;
  letter-spacing: -0.32px !important;
}
.bold-text-2,
.text-2 {
  font-size: 14px !important;
  line-height: 20px !important;
  letter-spacing: -0.28px !important;
}
.bold-text-3.text-3{
  font-size: 12px !important;
  line-height: 14px !important;
  letter-spacing: -0.24px !important;
}