#react-paginate ul {
  display: flex;
  justify-content: center;
  padding-left: 0;
}

.wrap-pagination#react-paginate li.active {
  outline: none;
  background-color: #1f4bff;
  color: white;
}

.wrap-pagination#react-paginate li.active:hover {
  background-color: #1f4bff;
}

.wrap-pagination#react-paginate {
  width: 100%;
}

.wrap-pagination#react-paginate li {
  width: 28px;
  height: 28px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #000d23;
  font-size: 14px;
  font-weight: 500;
  margin-right: 10px;
  cursor: pointer;
}

.wrap-pagination#react-paginate ul.list-item li {
  margin-right: 0;
}

.wrap-pagination#react-paginate ul.list-item {
  border-radius: 8px;
}

.wrap-pagination#react-paginate li a:focus {
  outline: none;
}

.wrap-pagination#react-paginate li:hover {
  background-color: #ebeeff;
  transition: 0.3s;
}

.wrap-pagination#react-paginate .text-show {
  margin-top: 14px;
  color: #8f8f8f;
  font-weight: 400;
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.wrap-pagination#react-paginate .text-show span {
  color: #1f4bff;
  font-weight: 600;
  font-size: 14px;
}
