/* style label input */
.label-input-small{
    font-weight: 600;
    font-size: 12px;
    color: #5c5c5c;
}

/* style label input large */
.label-input-small-large{
    font-weight: 600;
    font-size: 14px;
    color: #5c5c5c;
}


/* start input default small */
.default-field-input .pincode-input-container .pincode-input-text {
    border: 1px solid #c2c2c2 !important;
    color: gray;
    border-radius: 8px;
    font-size: 14px;
    font-weight: 400;
    width: 36px !important;
    height: 36px !important;
    padding: 8px !important;
}
.default-field-input .pincode-input-container .pincode-input-text:focus
{
    border: 1px solid #1F4BFF !important;
}
/* end input default small */

/* start input disabled small */
.disabled-field-input .pincode-input-container .pincode-input-text {
    border: 1px solid #c2c2c2 !important;
    color: gray;
    border-radius: 8px;
    font-size: 14px;
    font-weight: 400;
    width: 36px !important;
    height: 36px !important;
    padding: 8px !important;
    background: #f5f5f5 !important;
}
.disabled-field-input .pincode-input-container .pincode-input-text:focus
{
    border: 1px solid #1F4BFF !important;
}
/* end input disabled small */

/* start input required small */
.required-field-input .pincode-input-container .pincode-input-text {
    border: 1px solid #FF1F26 !important;
    color: gray;
    border-radius: 8px;
    font-size: 14px;
    font-weight: 400;
    width: 36px !important;
    height: 36px !important;
    padding: 8px !important;
}

.required-field-input .pincode-input-container .pincode-input-text:focus {
    border: 1px solid #C2C2C2 !important;
}
/* end input required small */

/* start input success small */
.success-field-input .pincode-input-container .pincode-input-text {
    border: 1px solid #0AD674 !important;
    color: gray;
    border-radius: 8px;
    font-size: 14px;
    font-weight: 400;
    width: 36px !important;
    height: 36px !important;
    padding: 8px !important;
}

.success-field-input .pincode-input-container .pincode-input-text:focus {
    border: 1px solid #C2C2C2 !important;
}
/* end input success small */

/* start input success small */
.notEnought-field-input .pincode-input-container .pincode-input-text {
    border: 1px solid #FF711F !important;
    color: gray;
    border-radius: 8px;
    font-size: 14px;
    font-weight: 400;
    width: 36px !important;
    height: 36px !important;
    padding: 8px !important;
}

.notEnought-field-input .pincode-input-container .pincode-input-text:focus {
    border: 1px solid #C2C2C2 !important;
}

/* end input notEnought small */

/* start hover text label ketika focus input large  */

.default-field-input:hover .label-input-small{
    color: #1F4BFF;
}
/* end hover text label ketika focus input  */

/* start hover text label ketika focus input large  */

.default-field-input:hover .label-input-small-large{
    color: #1F4BFF;
}
/* end hover text label ketika focus input  */

.hovered-pin:hover span:first-child{
    color: #1F4BFF !important;
    transition: 0.2s;
}
.default-field-input-large.hovered-pin:hover .pincode-input-container .pincode-input-text {
    border-color: #1F4BFF !important;
    transition: 0.2s;
}
.default-field-input.hovered-pin:hover .pincode-input-container .pincode-input-text {
    border-color: #1F4BFF !important;
    transition: 0.2s;
}










/* start input default large */
.default-field-input-large .pincode-input-container .pincode-input-text {
    border: 1px solid #c2c2c2 !important;
    color: gray;
    border-radius: 8px;
    font-size: 14px;
    font-weight: 400;
    width: 52px !important;
    height: 52px !important;
    padding: 8px !important;
}

.default-field-input-large .pincode-input-container .pincode-input-text:focus {
    border: 1px solid #1F4BFF !important;
}

/* end input default large */

/* start input disabled large */
.disabled-field-input-large .pincode-input-container .pincode-input-text {
    border: 1px solid #c2c2c2 !important;
    color: gray;
    border-radius: 8px;
    font-size: 14px;
    font-weight: 400;
    width: 52px !important;
    height: 52px !important;
    padding: 8px !important;
    background: #f5f5f5 !important;
}

.disabled-field-input-large .pincode-input-container .pincode-input-text:focus {
    border: 1px solid #1F4BFF !important;
}

/* end input disabled large */

/* start input required large */
.required-field-input-large .pincode-input-container .pincode-input-text {
    border: 1px solid #FF1F26 !important;
    color: gray;
    border-radius: 8px;
    font-size: 14px;
    font-weight: 400;
    width: 52px !important;
    height: 52px !important;
    padding: 8px !important;
}

.required-field-input-large .pincode-input-container .pincode-input-text:focus {
    border: 1px solid #C2C2C2 !important;
}

/* end input required large */

/* start input success large */
.success-field-input-large .pincode-input-container .pincode-input-text {
    border: 1px solid #0AD674 !important;
    color: gray;
    border-radius: 8px;
    font-size: 14px;
    font-weight: 400;
    width: 52px !important;
    height: 52px !important;
    padding: 8px !important;
}

.success-field-input-large .pincode-input-container .pincode-input-text:focus {
    border: 1px solid #C2C2C2 !important;
}

/* end input success large */

/* start input success large */
.notEnought-field-input-large .pincode-input-container .pincode-input-text {
    border: 1px solid #FF711F !important;
    color: gray;
    border-radius: 8px;
    font-size: 14px;
    font-weight: 400;
    width: 52px !important;
    height: 52px !important;
    padding: 8px !important;
}

.notEnought-field-input-large .pincode-input-container .pincode-input-text:focus {
    border: 1px solid #C2C2C2 !important;
}

/* end input notEnought large */
