table {
  border-collapse: separate !important;
  border-spacing: 0px 0px !important;
}

th {
  text-align: left;
  font-weight: 600;
  font-size: 14px;
  color: #5c5c5c;
  padding: 16px;
  background-color: #f5f5f5;
  box-shadow: 0px -0.5px 0px #c2c2c2, 0px 0.5px 0px #c2c2c2;
}

tr {
  transition: all 0.3s;
}

tr:hover {
  cursor: pointer;
  background-color: #ebeeff;
}
tr:hover td {
  box-shadow: 0px -0.5px 0px #1f4bff, 0px 0.5px 0px #1f4bff;
}

td {
  box-shadow: 0px -0.5px 0px #c2c2c2, 0px 0.5px 0px #c2c2c2;
  padding: 16px;
  font-size: 14px;
}
