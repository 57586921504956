@layer components {
  .overlay {
    @apply absolute w-full h-screen inset-0 bg-black bg-opacity-50 z-10;
  }
  .leftbar {
    @apply absolute w-72 h-screen bg-white z-10 -left-full transform transition-all duration-300 ease-in;
  }
  .leftbar-transition {
    @apply -left-96 translate-x-96 transform transition-all duration-300 ease-out;
  }
  .leftbar-header {
    @apply h-16 w-full shadow-gray bg-white flex px-5 justify-between self-center;
  }
  .menu {
    @apply flex text-gray-400 hover:text-blue-hedgehog py-3 pl-4 self-center font-semibold;
  }
  .verification {
    @apply flex rounded-lg px-4 py-2 text-white font-semibold focus:outline-none;
  }
}
