/* start Radio primary no text*/
.wrap-radio {
  position: relative;
  padding-left: 23px;
  margin-bottom: 12px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.checkmark {
  position: absolute;
  top: 50%;
  left: 0;
  height: 16px;
  width: 16px;
  background-color: #fff;
  border-radius: 100px;
  border: 1px solid #8f8f8f;
  transform: translateY(-50%);
}
.checkmark:hover,
.checkmark-large:hover {
  border-color:#0025B8;
  cursor: pointer;
}
.checkmark:hover.checkmark::after,
.checkmark-large:hover.checkmark-large::after {
  border-color:#0025B8;
}

.wrap-radio:hover input ~ .checkmark {
  background-color: white;
}
.wrap-radio input:checked ~ .checkmark {
  background-color: white;
}
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}
.wrap-radio input:checked ~ .checkmark:after {
  display: block;
}
.wrap-radio .checkmark:after {
  top: 50%;
  left: 50%;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background: white;
  border: 4px solid #1f4bff;
  transform: translate(-50%, -50%);
}

.radio-cs {
  opacity: 0;
}
/* end Radio primary no text*/

/* start Radio secondary no text*/
.wrap-radio-secondary {
  position: relative;
  padding-left: 23px;
  margin-bottom: 12px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.checkmark-secondary {
  position: absolute;
  top: 50%;
  left: 0;
  height: 16px;
  width: 16px;
  background-color: #fff;
  border-radius: 100px;
  border: 1px solid #0025b8;
  transform: translateY(-50%);
}
.wrap-radio-secondary:hover input ~ .checkmark-secondary {
  background-color: white;
}
.wrap-radio-secondary input:checked ~ .checkmark-secondary {
  background-color: white;
}
.checkmark-secondary:after {
  content: "";
  position: absolute;
  display: none;
}
.wrap-radio-secondary input:checked ~ .checkmark-secondary:after {
  display: block;
}
.wrap-radio-secondary .checkmark-secondary:after {
  top: 50%;
  left: 50%;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background: white;
  border: 4px solid #0025b8;
  transform: translate(-50%, -50%);
}
.radio-cs-secondary {
  opacity: 0;
}
/* end Radio secondary no text*/

/* start Radio DISABLED no text*/
.wrap-radio-disabled {
  position: relative;
  padding-left: 23px;
  margin-bottom: 12px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.checkmark-disabled {
  position: absolute;
  top: 50%;
  left: 0;
  height: 16px;
  width: 16px;
  background-color: #f5f5f5;
  border-radius: 100px;
  border: 1px solid #c2c2c2;
  transform: translateY(-50%);
}
.wrap-radio-disabled input:checked ~ .checkmark-disabled {
  background-color: #f5f5f5;
}
.checkmark-disabled:after {
  content: "";
  position: absolute;
  display: none;
}
.wrap-radio-disabled input:checked ~ .checkmark-disabled:after {
  display: block;
}
.wrap-radio-disabled .checkmark-disabled:after {
  top: 50%;
  left: 50%;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: #c2c2c2;
  transform: translate(-50%, -50%);
}
.radio-cs-disabled {
  opacity: 0;
}
/* end Radio DISABLED no text*/

/* start Radio primary large no text*/
.wrap-radio-large {
  position: relative;
  padding-left: 23px;
  margin-bottom: 12px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.checkmark-large {
  position: absolute;
  top: 50%;
  left: 0;
  height: 24px;
  width: 24px;
  background-color: #fff;
  border-radius: 100px;
  border: 1px solid #8f8f8f;
  transform: translateY(-50%);
}
.wrap-radio-large:hover input ~ .checkmark-large {
  background-color: white;
}
.wrap-radio-large input:checked ~ .checkmark-large {
  background-color: white;
}
.checkmark-large:after {
  content: "";
  position: absolute;
  display: none;
}
.wrap-radio-large input:checked ~ .checkmark-large:after {
  display: block;
}
.wrap-radio-large .checkmark-large:after {
  top: 50%;
  left: 50%;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background: white;
  border: 6px solid #1f4bff;
  transform: translate(-50%, -50%);
}
.radio-cs-large {
  opacity: 0;
}
/* end Radio primary large no text*/

/* start Radio secondary-large no text*/
.wrap-radio-secondary-large {
  position: relative;
  padding-left: 23px;
  margin-bottom: 12px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.checkmark-secondary-large {
  position: absolute;
  top: 50%;
  left: 0;
  height: 24px;
  width: 24px;
  background-color: #fff;
  border-radius: 100px;
  border: 1px solid #0025b8;
  transform: translateY(-50%);
}
.wrap-radio-secondary-large:hover input ~ .checkmark-secondary-large {
  background-color: white;
}
.wrap-radio-secondary-large input:checked ~ .checkmark-secondary-large {
  background-color: white;
}
.checkmark-secondary-large:after {
  content: "";
  position: absolute;
  display: none;
}
.wrap-radio-secondary-large input:checked ~ .checkmark-secondary-large:after {
  display: block;
}
.wrap-radio-secondary-large .checkmark-secondary-large:after {
  top: 50%;
  left: 50%;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background: white;
  border: 6px solid #0025b8;
  transform: translate(-50%, -50%);
}
.radio-cs-secondary-large {
  opacity: 0;
}
/* end Radio secondary-large no text*/

/* start Radio large DISABLED no text*/
.wrap-radio-disabled-large {
  position: relative;
  padding-left: 23px;
  margin-bottom: 12px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.checkmark-disabled-large {
  position: absolute;
  top: 50%;
  left: 0;
  height: 24px;
  width: 24px;
  background-color: #f5f5f5;
  border-radius: 100px;
  border: 1px solid #c2c2c2;
  transform: translateY(-50%);
}
.wrap-radio-disabled-large input:checked ~ .checkmark-disabled-large {
  background-color: #f5f5f5;
}
.checkmark-disabled-large:after {
  content: "";
  position: absolute;
  display: none;
}
.wrap-radio-disabled-large input:checked ~ .checkmark-disabled-large:after {
  display: block;
}
.wrap-radio-disabled-large .checkmark-disabled-large:after {
  top: 50%;
  left: 50%;
  width: 13px;
  height: 13px;
  border-radius: 50%;
  background: #c2c2c2;
  transform: translate(-50%, -50%);
}
.radio-cs-disabled-large {
  opacity: 0;
}
/* end Radio large DISABLED no text*/
