.first-login img {
  margin-left: 6rem;
}
.form-first-login span{
    text-align: right;
}
.form-first-login.pin span{
    width: 6.5rem;
    text-align: right;
}
