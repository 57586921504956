.table-track-stock p {
    display: none;

}

.table-track-stock tbody {
    width: 53vh;
    width: 100% !important;

}

.table-track-stock th {
    background: #F5F5F5;
    border: 1px solid #C2C2C2;
}

.table-track-stock td {
    border: 1px solid #c2c2c24a;
    padding: 13px 16px;
}

.table-track-stock .text-field-small {
    border: none;
    padding-left: 0;
    padding-right: 14px;
}

table.table-track-stock,
.table-track-stock tr td {}

.table-track-stock tbody {
    display: block !important;
    height: 52vh !important;
    overflow: auto !important;
}

.table-track-stock tbody::-webkit-scrollbar {
    display: none;
}

.table-track-stock thead,
.table-track-stock tbody tr {
    display: table !important;
    width: 100% !important;
    table-layout: fixed !important;
}

.table-track-stock thead {
    /* width: calc(100% - 1em) !important; */
}

.table-track-stock {

    width: 100% !important;
}
