@layer components {
  .btn-disabled {
    @apply cursor-not-allowed
  }
  .btn-primary{
    @apply rounded-lg px-4 py-2 text-sm cursor-pointer text-white font-semibold focus:outline-none duration-500
  }

  .btn-secondary {
    @apply rounded-lg px-4 py-2 text-sm cursor-pointer focus:outline-none outline-none duration-500 font-semibold
  }

  .btn-outlined {
    @apply rounded-lg px-4 py-2 text-sm cursor-pointer duration-500 focus:outline-none outline-none border bg-white font-semibold
  }

  .btn-link {
    @apply rounded-lg px-4 py-2 text-sm cursor-pointer bg-white font-semibold
  }
}