/*start checkbox small style ceklist-light-blue tricky */
.wrap-checkbox-light-blue {
    position: relative;
    cursor: pointer;
}

.wrap-checkbox-light-blue input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}

.ceklist-light-blue {
    position: absolute;
    top: 0;
    left: 0;
    /*ukuran checkbox*/
    height: 40px;
    width: 40px;
    background-color: #EBEEFF;
    /*warna border default*/
    /* border: 1px solid #8f8f8f; */
    border-radius: 8px;
}

.ceklist-light-blue:hover {
    /* border: 1px solid #0025B8; */
}

.wrap-checkbox-light-blue input:checked~.ceklist-light-blue {
    /*warna background ketika di cheklist*/
    background-color: #EBEEFF;
}
.wrap-checkbox-light-blue input:checked~.ceklist-light-blue {
    /*warna background ketika di cheklist*/
    background-color: #EBEEFF;
}

.wrap-checkbox-light-blue input:checked~.ceklist-light-blue:hover{
    /*warna background ketika di cheklist*/
    background-color: #EBEEFF;
}

.ceklist-light-blue:after {
    content: "";
    position: absolute;
    display: none;
}

.wrap-checkbox-light-blue input:checked~.ceklist-light-blue:after {
    display: block;
}

.wrap-checkbox-light-blue .ceklist-light-blue:after {
            top: 18%;
            left: 36%;
            transform: translate(-50%, -50%);
            width: 11px;
            height: 19px;
            border: solid #1F4BFF;
            border-width: 0 4px 4px 0;
            transform: rotate(45deg);
            border-radius: 3px;
}

/*end checkbox small style ceklist-light-blue tricky */