.setting-location span,
.setting-location p {
    width: 14rem;
    color: #525252;
}
.setting-location textarea {
    font-size: 14px !important;
    font-weight: 400 !important;
}

.setting-location .nomor-telepon p {
    width: 16rem;
    
}

.setting-location .nomor-telepon p.dash {
    width: inherit;
}