@layer components {
  .btn-icon {
    @apply w-9 h-9 p-2 mx-1 rounded-lg flex items-center justify-center focus:outline-none;
  }
  .primary {
    @apply rounded-lg w-full justify-center flex text-white focus:outline-none items-center;
  }
  .primary-green {
    @apply rounded-lg bg-green-03 text-white focus:outline-none items-center;
  }
  .primary-red {
    @apply rounded-lg bg-red-600 text-white focus:outline-none items-center;
  }
  .primary-blue {
    @apply rounded-lg bg-blue-03 w-full justify-center flex text-white focus:outline-none items-center;
  }

  /* .btn-blue-bg {
    @apply ;
  }

  .btn-blue-bg-hover {
    @apply ;
  }

  .btn-blue-bg:hover {
    @apply btn-blue-bg-hover;
  } */
  .btn-bg {
    @apply rounded-lg px-4 py-2 text-sm cursor-pointer text-white font-semibold focus:outline-none outline-none duration-100;
  }
  .btn-secondary {
    @apply rounded-lg px-4 py-2 text-sm cursor-pointer focus:outline-none outline-none duration-100 font-semibold;
  }
  .btn-border {
    @apply rounded-lg px-4 py-2 text-sm cursor-pointer duration-100 focus:outline-none outline-none border bg-white font-semibold;
  }
  .btn-text {
    @apply rounded-lg px-4 py-2 text-sm cursor-pointer duration-100 focus:outline-none bg-white outline-none font-semibold;
  }
  .btn-icon-left {
    @apply rounded-lg px-4 py-2 text-sm cursor-pointer text-white  duration-100 focus:outline-none font-semibold flex items-center;
  }
  .btn-icon-left-secondary {
    @apply rounded-lg px-4 py-2 text-sm cursor-pointer focus:outline-none outline-none duration-100 font-semibold flex items-center;
  }
  .btn-icon-left-border {
    @apply rounded-lg px-4 py-2 text-sm cursor-pointer border bg-white font-semibold flex items-center outline-none focus:outline-none duration-100;
  }
  .btn-icon-right {
    @apply rounded-lg px-4 py-2 text-sm cursor-pointer text-white  duration-100 focus:outline-none font-semibold flex items-center;
  }
  .btn-icon-right-secondary {
    @apply rounded-lg px-4 py-2 text-sm cursor-pointer text-white  duration-100 focus:outline-none font-semibold flex items-center outline-none;
  }
  .btn-icon-right-border {
    @apply rounded-lg px-4 py-2 text-sm cursor-pointer border bg-white font-semibold flex items-center outline-none focus:outline-none duration-100;
  }
  .btn-icons {
    @apply rounded-lg cursor-pointer  duration-100 focus:outline-none outline-none p-3;
  }
  .btn-icons-secondary {
    @apply rounded-lg cursor-pointer duration-100 focus:outline-none outline-none p-3;
  }
  .btn-icons-border {
    @apply rounded-lg cursor-pointer bg-white border duration-100 focus:outline-none outline-none p-3;
  }
  /* text field (small) */
  .text-field-small {
    @apply w-60 pl-4 py-2 border text-sm duration-100 focus:outline-none outline-none placeholder-gray-03 border-gray-04 rounded-lg;
  }

  /* text field (small) unit */
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
