/*start checkbox small style ceklist-dark-blue tricky */
.wrap-checkbox-dark-blue {
    position: relative;
    cursor: pointer;
}

.wrap-checkbox-dark-blue input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}

.ceklist-dark-blue {
    position: absolute;
    top: 0;
    left: 0;
    /*ukuran checkbox*/
    height: 40px;
    width: 40px;
    background-color: #0025B8;
    /*warna border default*/
    /* border: 1px solid #8f8f8f; */
    border-radius: 8px;
}

.ceklist-dark-blue:hover {
    /* border: 1px solid #0025B8; */
}

.wrap-checkbox-dark-blue input:checked~.ceklist-dark-blue {
    /*warna background ketika di cheklist*/
    background-color: #0025B8;
}
.wrap-checkbox-dark-blue input:checked~.ceklist-dark-blue {
    /*warna background ketika di cheklist*/
    background-color: #0025B8;
}

.wrap-checkbox-dark-blue input:checked~.ceklist-dark-blue:hover{
    /*warna background ketika di cheklist*/
    background-color: #0025B8;
}

.ceklist-dark-blue:after {
    content: "";
    position: absolute;
    display: none;
}

.wrap-checkbox-dark-blue input:checked~.ceklist-dark-blue:after {
    display: block;
}

.wrap-checkbox-dark-blue .ceklist-dark-blue:after {
            top: 18%;
            left: 36%;
            transform: translate(-50%, -50%);
            width: 11px;
            height: 19px;
            border: solid white;
            border-width: 0 4px 4px 0;
            transform: rotate(45deg);
            border-radius: 3px;
}

/*end checkbox small style ceklist-dark-blue tricky */