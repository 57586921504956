.dropdown-category button{
    padding-top: 13px !important;
    padding-bottom: 13px !important;
}
.dropdown-category .dropdown{
    top: 3.5rem;
    width: 100%;
    border-radius: 8px;
    overflow: hidden;
}

.dropdown-color .menu-container button{
    background-color: white;
}
table.table-pilih-produk tbody tr td{
    background-color: white;

}
