.calender .react-datepicker__input-container{
    background-color: #EBEEFF;
    border-radius: 8px;
    padding: 8px 0;

}
.calender .react-datepicker__input-container input{
    background-color: transparent;
    padding-left: 40px;
    width: 100%;
    color: #0025B8;
}
.calender .react-datepicker__input-container input:focus{
    outline: none;
}
.calender .react-datepicker__input-container input:hover{
    cursor: pointer;
}
.calender .react-datepicker__input-container input{
    font-size: 14px !important;
    line-height: 20px !important;
    letter-spacing: -0.28px !important;
    font-weight: 600 !important;
}

.calender .react-datepicker__triangle{
    display: none;
}

.calender .react-datepicker{
        font-family: 'Work Sans' !important;
        font-size: 14px !important;
        line-height: 20px !important;
        letter-spacing: -0.28px !important;
        background-color: #fff;
        color: #000D23;
        border: none;
        border-radius: 8px;
        box-shadow: 0px 8px 24px rgba(0, 13, 35, 0.1);
}

.calender .react-datepicker__header{
    padding-top: 16px !important;
    background-color: white !important;
    border-bottom: none !important;
}

.calender .react-datepicker__navigation{
    top: 21px;
}
.calender .react-datepicker__current-month{
color: #1F4BFF;
font-size: 16px !important;
line-height: 24px !important;
letter-spacing: -0.32px !important;
font-weight: 600 !important;
}

.calender .react-datepicker__day-name{
    font-size: 12px !important;
    line-height: 14px !important;
    letter-spacing: -0.24px !important;
    color: #8F8F8F !important;
}

.calender .react-datepicker__day-names,
.react-datepicker__week {
    padding-top: 8px !important;
}
.calender .react-datepicker__day-names {
    padding-top: 16px !important;
    text-transform: uppercase !important;
    display: flex;
    justify-content: space-between;
    padding: 0 13px;

}

.calender .react-datepicker__day-name {
    font-weight: 600 !important;

}

.calender .react-datepicker__day--selected{
    font-size: 14px !important;
    line-height: 20px !important;
    letter-spacing: -0.28px !important;
    font-weight: 400 !important;
    padding: 4px 8px;
    border-radius: 8px;
}

.calender .react-datepicker__day{
    width: 38px;
}

.calender .react-datepicker__day-name,
.react-datepicker__day{
    margin: 2px;
}

.calender .react-datepicker__month-container{
    padding-bottom: 13px;
}
