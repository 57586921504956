.transfer-stock p{
    width: 16rem;
    text-align: right;
}
.table-transfer-stock table{
    border: 1px solid #c2c2c2;
}
.table-transfer-stock table th,
.table-transfer-stock table tr td {
    border: 1px solid #c2c2c2;
}
.table-transfer-stock tbody tr td{
    background-color: #f5f5f5;
    border-bottom: 1px solid #c2c2c2;
    position: relative;
}
.table-transfer-stock tbody tr td:last-child{
    background-color: #ffffff;
    text-align: right;
}

.table-transfer-stock thead tr th:last-child{
    text-align: right;
}