.App {
  font-family: sans-serif;
  text-align: center;
  margin-top: 20px;
  height: 316px !important;
  max-width: 300% !important;
  overflow-x: scroll;
}

.App canvas {
  /* height: 430px !important; */
  height: 280px !important;
}

.separator {
  margin: 15px;
}

.spacing-graph{
  overflow: hidden;
  width: 78vw;
  height: auto;
}
