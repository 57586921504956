.recipt-manage .text-field-small{
    padding-right: 55px;
}

.recipt-manage p:first-child{
    display: none;
}

.recipt-manage-card{
    min-width: 288px;
    margin-left: 16px;
}
.recipt-manage-card:first-child{
    margin-left: 0;
}
.recipt-manage-card ul{
    height: 53vh;
    overflow-y: scroll;
}
.recipt-manage-card ul::-webkit-scrollbar{
    display: none;
}