/*start checkbox large style ceklist tricky */
.wrap-checkbox-large {
  position: relative;
  cursor: pointer;
}
.wrap-checkbox-large input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}
.ceklist-large {
  position: absolute;
  top: 0;
  left: 0;
  /*ukuran checkbox*/
  height: 24px;
  width: 24px;
  background-color: #fff;
  /*warna border default*/
  border: 1px solid #8f8f8f;
  border-radius: 4px;
}
.wrap-checkbox-large input:checked ~ .ceklist-large {
  /*warna background ketika di cheklist*/
  background-color: #1f4bff;
}
.ceklist-large:after {
  content: "";
  position: absolute;
  display: none;
}
.wrap-checkbox-large input:checked ~ .ceklist-large:after {
  display: block;
}
.wrap-checkbox-large .ceklist-large:after {
  left: 7px;
  top: 3px;
  width: 8px;
  height: 12px;
  /* warna ceklist */
  border: solid white;
  border-width: 0 3px 3px 0;

  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}
/*end checkbox large style ceklist tricky */

/*start checkbox large style ceklist tricky blue secondary*/
.wrap-checkbox-large-secondary {
  position: relative;
  cursor: pointer;
}
.wrap-checkbox-large-secondary input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}
.ceklist-large-secondary {
  position: absolute;
  top: 0;
  left: 0;
  /*ukuran checkbox*/
  height: 24px;
  width: 24px;
  background-color: #fff;
  /*warna border default*/
  border: 1px solid #0025b8;
  border-radius: 4px;
}
.wrap-checkbox-large-secondary input:checked ~ .ceklist-large-secondary {
  /*warna background ketika di cheklist*/
  background-color: #0025b8;
}
.ceklist-large-secondary:after {
  content: "";
  position: absolute;
  display: none;
}
.wrap-checkbox-large-secondary input:checked ~ .ceklist-large-secondary:after {
  display: block;
}
.wrap-checkbox-large-secondary .ceklist-large-secondary:after {
  left: 7px;
  top: 3px;
  width: 8px;
  height: 12px;
  /* warna ceklist */
  border: solid white;
  border-width: 0 3px 3px 0;

  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}
/*end checkbox large style ceklist tricky blue seoncdary*/

/*start checkbox small style ceklist tricky */
.wrap-checkbox {
  position: relative;
  cursor: pointer;
}
.wrap-checkbox input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}
.ceklist {
  position: absolute;
  top: 0;
  left: 0;
  /*ukuran checkbox*/
  height: 16px;
  width: 16px;
  background-color: #fff;
  /*warna border default*/
  border: 1px solid #8f8f8f;
  border-radius: 4px;
}
.ceklist:hover,
.ceklist-large:hover,
.ceklist-large-secondary:hover,
.ceklist-secondary:hover {
  border: 1px solid #0025B8;
}
.wrap-checkbox input:checked ~ .ceklist {
  /*warna background ketika di cheklist*/
  background-color: #1f4bff;
}
.wrap-checkbox input:checked ~ .ceklist:hover,
.wrap-checkbox-large input:checked~.ceklist-large:hover,
.wrap-checkbox-large-secondary input:checked~.ceklist-large-secondary:hover,
.wrap-checkbox-secondary input:checked~.ceklist-secondary:hover
{
  /*warna background ketika di cheklist*/
  background-color: #0025B8;
}
.ceklist:after {
  content: "";
  position: absolute;
  display: none;
}
.wrap-checkbox input:checked ~ .ceklist:after {
  display: block;
}
.wrap-checkbox .ceklist:after {
  left: 4px;
  top: 1px;
  width: 5px;
  height: 8px;

  /* warna ceklist */

  border: solid white;
  border-width: 0 2px 2px 0;

  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}
/*end checkbox small style ceklist tricky */

/*start checkbox small style ceklist tricky SECONDARY*/
.wrap-checkbox-secondary {
  position: relative;
  cursor: pointer;
}
.wrap-checkbox-secondary input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}
.ceklist-secondary {
  position: absolute;
  top: 0;
  left: 0;
  /*ukuran checkbox-secondary*/
  height: 16px;
  width: 16px;
  background-color: #fff;
  /*warna border default*/
  border: 1px solid #0025b8;
  border-radius: 4px;
}
.wrap-checkbox-secondary input:checked ~ .ceklist-secondary {
  /*warna background ketika di cheklist*/
  background-color: #0025b8;
}
.ceklist-secondary:after {
  content: "";
  position: absolute;
  display: none;
}
.wrap-checkbox-secondary input:checked ~ .ceklist-secondary:after {
  display: block;
}
.wrap-checkbox-secondary .ceklist-secondary:after {
  left: 4px;
  top: 1px;
  width: 5px;
  height: 8px;

  /* warna ceklist-secondary */

  border: solid white;
  border-width: 0 2px 2px 0;

  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}
/*end checkbox-secondary small style ceklist-secondary tricky SECONDARY*/

/*start checkbox small style ceklist tricky DISABLED*/
.wrap-checkbox-disabled {
  position: relative;
  cursor: pointer;
}
.wrap-checkbox-disabled input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}
.ceklist-disabled {
  position: absolute;
  top: 0;
  left: 0;
  /*ukuran checkbox-disabled*/
  height: 16px;
  width: 16px;
  background-color: #f5f5f5;
  /*warna border default*/
  border: 1px solid #c2c2c2;
  border-radius: 4px;
}
.wrap-checkbox-disabled input:checked ~ .ceklist-disabled {
  /*warna background ketika di cheklist*/
  background-color: #f5f5f5;
}
.ceklist-disabled:after {
  content: "";
  position: absolute;
  display: none;
}
.wrap-checkbox-disabled input:checked ~ .ceklist-disabled:after {
  display: block;
}
.wrap-checkbox-disabled .ceklist-disabled:after {
  left: 4px;
  top: 1px;
  width: 5px;
  height: 8px;

  /* warna ceklist */

  border: solid #c2c2c2;
  border-width: 0 2px 2px 0;

  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}
/*end checkbox-disabled small style ceklist tricky DISABLED*/

/*start checkbox small-dash style ceklist tricky */
.wrap-checkbox-dash {
  position: relative;
  cursor: pointer;
}
.wrap-checkbox-dash input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}
.ceklist-dash {
  position: absolute;
  top: 0;
  left: 0;
  /*ukuran checkbox*/
  height: 16px;
  width: 16px;
  background-color: #fff;
  /*warna border default*/
  border: 1px solid #1f4bff;
  border-radius: 4px;
}
.wrap-checkbox-dash input:checked ~ .ceklist-dash {
  /*warna background ketika di cheklist*/
  background-color: white;
}
.wrap-checkbox-dash input:checked ~ .ceklist-dash:hover,
.wrap-checkbox-large-dash input:checked~.ceklist-large-dash:hover
   {
  /*warna background ketika di cheklist*/
  border-color: #0025B8;
}
.ceklist-dash:after {
  content: "";
  position: absolute;
  display: none;
}
.wrap-checkbox-dash input:checked ~ .ceklist-dash:after {
  display: block;
}
.wrap-checkbox-dash .ceklist-dash:after {
  left: 3px;
  top: 5px;
  width: 8px;
  border: solid #1f4bff;
  border-width: 0 2px 2px 0;
}
/*end checkbox small-dash style ceklist-dash tricky */

/*start checkbox small-dash style ceklist tricky */
.wrap-checkbox-dash-secondary {
  position: relative;
  cursor: pointer;
}
.wrap-checkbox-dash-secondary input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}
.ceklist-dash-secondary {
  position: absolute;
  top: 0;
  left: 0;
  /*ukuran checkbox*/
  height: 16px;
  width: 16px;
  background-color: #fff;
  /*warna border default*/
  border: 1px solid #0025b8;
  border-radius: 4px;
}
.wrap-checkbox-dash-secondary input:checked ~ .ceklist-dash-secondary {
  /*warna background ketika di cheklist*/
  background-color: white;
}
.ceklist-dash-secondary:after {
  content: "";
  position: absolute;
  display: none;
}
.wrap-checkbox-dash-secondary input:checked ~ .ceklist-dash-secondary:after {
  display: block;
}
.wrap-checkbox-dash-secondary .ceklist-dash-secondary:after {
  left: 3px;
  top: 5px;
  width: 8px;
  border: solid #0025b8;
  border-width: 0 2px 2px 0;
}
/*end checkbox small-dash-secondary style ceklist-dash-secondary tricky */

/*start checkbox small-dash style ceklist tricky DISABLED*/
.wrap-checkbox-dash-disabled {
  position: relative;
  cursor: pointer;
}
.wrap-checkbox-dash-disabled input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}
.ceklist-dash-disabled {
  position: absolute;
  top: 0;
  left: 0;
  /*ukuran checkbox*/
  height: 16px;
  width: 16px;
  background-color: #f5f5f5;
  /*warna border default*/
  border: 1px solid #c2c2c2;
  border-radius: 4px;
}
.wrap-checkbox-dash-disabled input:checked ~ .ceklist-dash-disabled {
  /*warna background ketika di cheklist*/
  background-color: #f5f5f5;
}
.ceklist-dash-disabled:after {
  content: "";
  position: absolute;
  display: none;
}
.wrap-checkbox-dash-disabled input:checked ~ .ceklist-dash-disabled:after {
  display: block;
}
.wrap-checkbox-dash-disabled .ceklist-dash-disabled:after {
  left: 3px;
  top: 5px;
  width: 8px;
  border: solid #c2c2c2;
  border-width: 0 2px 2px 0;
}
/*end checkbox small-dash style ceklist-dash tricky DISABLED*/

/*start checkbox large-dash style ceklist tricky */
.wrap-checkbox-large-dash {
  position: relative;
  cursor: pointer;
}
.wrap-checkbox-large-dash input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}
.ceklist-large-dash {
  position: absolute;
  top: 0;
  left: 0;
  /*ukuran checkbox*/
  height: 24px;
  width: 24px;
  background-color: #fff;
  /*warna border default*/
  border: 1px solid #1f4bff;
  border-radius: 4px;
}
.wrap-checkbox-large-dash input:checked ~ .ceklist-large-dash {
  /*warna background ketika di cheklist*/
  background-color: white;
}
.ceklist-large-dash:after {
  content: "";
  position: absolute;
  display: none;
}
.wrap-checkbox-large-dash input:checked ~ .ceklist-large-dash:after {
  display: block;
}
.wrap-checkbox-large-dash .ceklist-large-dash:after {
  left: 5px;
  top: -1px;
  width: 11px;
  height: 12px;
  /* warna ceklist */
  border: solid #1f4bff;
  border-width: 0 0 3px 0;
}
/*end checkbox large-dash style ceklist tricky */

/*start checkbox large-dash style ceklist tricky SECONDARY */
.wrap-checkbox-large-dash-secondary {
  position: relative;
  cursor: pointer;
}
.wrap-checkbox-large-dash-secondary input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}
.ceklist-large-dash-secondary {
  position: absolute;
  top: 0;
  left: 0;
  /*ukuran checkbox*/
  height: 24px;
  width: 24px;
  background-color: #fff;
  /*warna border default*/
  border: 1px solid #0025b8;
  border-radius: 4px;
}
.wrap-checkbox-large-dash-secondary
  input:checked
  ~ .ceklist-large-dash-secondary {
  /*warna background ketika di cheklist*/
  background-color: white;
}
.ceklist-large-dash-secondary:after {
  content: "";
  position: absolute;
  display: none;
}
.wrap-checkbox-large-dash-secondary
  input:checked
  ~ .ceklist-large-dash-secondary:after {
  display: block;
}
.wrap-checkbox-large-dash-secondary .ceklist-large-dash-secondary:after {
  left: 5px;
  top: -1px;
  width: 11px;
  height: 12px;
  /* warna ceklist */
  border: solid #0025b8;
  border-width: 0 0 3px 0;
}
/*end checkbox large-dash-secondary style ceklist tricky SECONDARY */
/*start checkbox large-dash DISABLED style ceklist tricky SECONDARY */
.wrap-checkbox-large-dash-disabled {
  position: relative;
  cursor: pointer;
}
.wrap-checkbox-large-dash-disabled input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}
.ceklist-large-dash-disabled {
  position: absolute;
  top: 0;
  left: 0;
  /*ukuran checkbox*/
  height: 24px;
  width: 24px;
  background-color: #F5F5F5;
  /*warna border default*/
  border: 1px solid #C2C2C2;
  border-radius: 4px;
}
.wrap-checkbox-large-dash-disabled
  input:checked
  ~ .ceklist-large-dash-disabled {
  /*warna background ketika di cheklist*/
  background-color: #F5F5F5;
}
.ceklist-large-dash-disabled:after {
  content: "";
  position: absolute;
  display: none;
}
.wrap-checkbox-large-dash-disabled
  input:checked
  ~ .ceklist-large-dash-disabled:after {
  display: block;
}
.wrap-checkbox-large-dash-disabled .ceklist-large-dash-disabled:after {
  left: 5px;
  top: -1px;
  width: 11px;
  height: 12px;
  /* warna ceklist */
  border: solid #C2C2C2;
  border-width: 0 0 3px 0;
}
/*end checkbox large-dash-DISABLED style ceklist tricky SECONDARY */

/*start checkbox large style ceklist tricky DISABLED */
.wrap-checkbox-large-disabled {
  position: relative;
  cursor: pointer;
}
.wrap-checkbox-large-disabled input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}
.ceklist-large-disabled {
  position: absolute;
  top: 0;
  left: 0;
  /*ukuran checkbox*/
  height: 24px;
  width: 24px;
  /* warna background checkbox default */
  background-color: #f5f5f5;
  /*warna border default*/
  border: 1px solid #8f8f8f;
  border-radius: 4px;
}
.wrap-checkbox-large-disabled input:checked ~ .ceklist-large-disabled {
  /*warna background ketika di cheklist*/
  background-color: #f5f5f5;
}
.ceklist-large-disabled:after {
  content: "";
  position: absolute;
  display: none;
}
.wrap-checkbox-large-disabled input:checked ~ .ceklist-large-disabled:after {
  display: block;
}
.wrap-checkbox-large-disabled .ceklist-large-disabled:after {
  left: 7px;
  top: 3px;
  width: 8px;
  height: 12px;
  /* warna ceklist */
  border: solid #c2c2c2;
  border-width: 0 3px 3px 0;

  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}
/*end checkbox large-disabled style ceklist tricky DISABLED*/
