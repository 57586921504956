.dragged .item {
  opacity: 0.25;
  border-top: 1px solid #999999;
  border-bottom: 1px solid #ffffff;
}

.floating .item {
  background-color: #ffffff;
  box-shadow: 0 4px 20px #666666;
}

.dl-item.floating {
  width: 85%;
}