/*start checkbox small style ceklist-light-red tricky */
.wrap-checkbox-light-red {
    position: relative;
    cursor: pointer;
}

.wrap-checkbox-light-red input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}

.ceklist-light-red {
    position: absolute;
    top: 0;
    left: 0;
    /*ukuran checkbox*/
    height: 40px;
    width: 40px;
    background-color: #FFEBEB;
    /*warna border default*/
    /* border: 1px solid #8f8f8f; */
    border-radius: 8px;
}

.ceklist-light-red:hover {
    /* border: 1px solid #5C5C5C; */
}

.wrap-checkbox-light-red input:checked~.ceklist-light-red {
    /*warna background ketika di cheklist*/
    background-color: #FFEBEB;
}
    
.wrap-checkbox-light-red input:checked~.ceklist-light-red {
    /*warna background ketika di cheklist*/
    background-color: #FFEBEB;
}

.wrap-checkbox-light-red input:checked~.ceklist-light-red:hover {
    /*warna background ketika di cheklist*/
    background-color: #FFEBEB;
}

.ceklist-light-red:after {
    content: "";
    position: absolute;
    display: none;
}

.wrap-checkbox-light-red input:checked~.ceklist-light-red:after {
    display: block;
}

.wrap-checkbox-light-red .ceklist-light-red:after {
    top: 18%;
    left: 36%;
    transform: translate(-50%, -50%);
    width: 11px;
    height: 19px;
    border: solid #B80006;
    border-width: 0 4px 4px 0;
    transform: rotate(45deg);
    border-radius: 3px;
}

/*end checkbox small style ceklist-light-red tricky */