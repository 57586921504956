.calender-range .rdrCalendarWrapper .rdrDateRangeWrapper{
    display: flex;
}
.calender-range .rdrCalendarWrapper{
    border-top-left-radius: 8px;

}
.calender-range .rdrDateDisplayWrapper{
    order: 3;
    background-color: white;
    position: relative;
    border-bottom-left-radius: 8px;
}
.calender-range .rdrMonths .rdrMonthsVertical {
    border-bottom: 1px solid gainsboro;
}

.calender-range .rdrDateInput{
    margin-top: 24px;
    position: static;
}
.calender-range .rdrDateInput:first-child::before{
    content: 'START';
    color: black;
    position: absolute;
    top: 14px;
    font-size: 12px !important;
    line-height: 14px !important;
    letter-spacing: -0.24px !important;
    font-weight: 600 !important;
    color: #5C5C5C !important;
}
.calender-range .rdrDateInput:last-child::before{
    content: 'END';
    color: black;
    position: absolute;
    top: 14px;
    font-size: 12px !important;
    line-height: 14px !important;
    letter-spacing: -0.24px !important;
    font-weight: 600 !important;
    color: #5C5C5C !important;
}

.calender-range .rdrDateDisplayItem{
    box-shadow: none;
    border: 1px solid #C2C2C2;
    border-radius: 8px;
    color: #8F8F8F;
}

.calender-range .rdrDateDisplayItem input{
    text-align: left;
    padding-left: 16px;
    padding-top: 5px;
    padding-bottom: 5px;
    box-sizing: content-box;
    font-size: 14px !important;
    line-height: 20px !important;
    letter-spacing: -0.28px !important;
    font-weight: 400;
}

.calender-range .rdrMonth{
    border-bottom: 1px solid gainsboro;
}

.calender-range .rdrStartEdge{
background-color: #1F4BFF;
border-top-left-radius: 8px;
border-bottom-left-radius: 8px;
} 
.calender-range .rdrEndEdge{
    background-color: #1F4BFF;
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
}

.calender-range .rdrDay{
    height: 43px;
}

.calender-range .rdrDayNumber {
    font-size: 14px !important;
    line-height: 20px !important;
    letter-spacing: -0.28px !important;
    font-weight: 400 !important;
}
.calender-range .rdrDay.rdrDayHovered {
    border-radius:none !important;
}
.calender-range .rdrDay.rdrDayHovered:hover {
    border-radius:none !important;
}
.calender-range .rdrDay {
    border-radius:none !important;
}
.calender-range .rdrDay:hover {
    border-radius:none !important;
}

.calender-range .rdrDayPreview{
    border: none;
}

.calender-range .rdrInRange{
    background-color: #1F4BFF;
}

.calender-range .date-range-wrapper{
    border-radius: 8px;
    filter: drop-shadow(0px 8px 24px rgba(0, 13, 35, 0.1));

}

.calender-range .input-group{
    background-color: #EBEEFF;
    border-radius: 8px;
    padding: 8px 0;
    position: relative;
    max-width: 180px;
}
.calender-range .input-group input{
    background-color: transparent;
    font-size: 14px !important;
    line-height: 20px !important;
    letter-spacing: -0.28px !important;
    font-weight: 600 !important;
    color: #0025B8;
    width: 100%;
    padding-left: 42px;
    
}
.calender-range .input-group input:hover{
    cursor: pointer;
    
}
.calender-range .input-group input:focus{
    outline: none;
    
}



