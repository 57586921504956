.transfer-dana p {
    display: none;
}

.transfer-dana .text-field-small {
    padding: 0 0;
    border: none;
}

.transfer-dana ul {
    top: 1.3rem;
}